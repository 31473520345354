/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeadTitle from "./home/HeadTitle";
import StepCard from "./home/StepCard";
import { DownArrow } from "../assets/Icons";
import { fetcher } from "../utils/helper";
import Loader from "./common/Loader";

const RequestListing = () => {
  const siteSetting = JSON.parse(localStorage.getItem("settings"));
  const [loading, setLoading] = useState(true);
  const [subItemData, setSubItemData] = useState([]);
  const [initialCount, setInitialCount] = useState(5);
  const [showAllStages, setShowAllStages] = useState(true);
  const [newUpdates, setNewUpdates] = useState(
    localStorage.getItem("newUpdates")
      ? JSON.parse(localStorage.getItem("newUpdates"))
      : []
  );
  let email = localStorage.getItem("userEmail");
  const listSubItem = async () => {
    setLoading(true);
    let payload = {
      email: email,
      limit: initialCount,
      skip: 0,
      email_column_id: siteSetting.selectedColumn.email,
      overall_status: siteSetting.selectedColumn.status,
    };
    try {
      const res = await fetcher(
        "/incorpify/getSubItemByEmail",
        "POST",
        JSON.stringify(payload)
      );
      if (res.success) {
        setSubItemData(res?.data?.response?.data.boards);
        if (initialCount > 5) {
          setShowAllStages(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const updateSubItemStatus = async (id) => {
    setLoading(true);
    let payload = {
      subitem_id: id,
      status_to_update: "In Progress",
      status_column_id: siteSetting.selectedColumn.status,
    };
    try {
      const res = await fetcher(
        "/incorpify/updateSubitemStatus",
        "POST",
        JSON.stringify(payload)
      );
      if (res.success) {
        listSubItem();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listSubItem();
  }, [initialCount]);

  useEffect(() => {
    if (subItemData.length > 0) {
      subItemData[0]?.items_page.items[0].subitems[0].column_values.forEach(
        (item) => {
          if (item.id === "status__1") {
            if (item.text === "Awaiting Action") {
              setSubItemData([]);
              updateSubItemStatus(
                subItemData[0]?.items_page.items[0].subitems[0].id
              );
            } else {
              setLoading(false);
            }
          }
        }
      );
      if (subItemData[0]?.items_page.items[0]?.new_updates) {
        if (
          Object.keys(subItemData[0]?.items_page.items[0].new_updates).length >
          0
        ) {
          localStorage.setItem(
            "newUpdates",
            JSON.stringify(
              Object.keys(subItemData[0]?.items_page.items[0].new_updates)
            )
          );
          setNewUpdates(
            Object.keys(subItemData[0]?.items_page.items[0].new_updates)
          );
        }
      }
    }
  }, [subItemData]);

  return (
    <>
      {loading && <Loader />}
      {subItemData.length > 0 && (
        <>
          <HeadTitle />
          <div className="mt-3">
            {subItemData[0]?.items_page.items[0].subitems.map((item, i) => {
              if (i + 1 <= initialCount)
                return (
                  <StepCard
                    step={item}
                    count={i + 1}
                    button_bg={siteSetting.button_bg}
                    notification={newUpdates.includes(item.id)}
                    title={siteSetting.selectedColumn.title}
                    statusId={siteSetting.selectedColumn.status}
                  />
                );
            })}
          </div>
          {showAllStages && subItemData[0]?.items_page?.items[0]?.subitems.length > 4 && (
            <div className="w-100 d-flex justify-content-center mt-5">
              <button
                className="btn btn-outline-custom d-flex align-items-center position-relative"
                onClick={() => {
                  setInitialCount(1000);
                }}
              >
                Show all stages
                <div
                  style={{ height: "100%", width: "20px" }}
                  className="position-relative"
                >
                  <span style={{ marginLeft: "5px" }}>
                    <DownArrow />
                  </span>
                </div>
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RequestListing;
