import { Button, Flex, Input, Select } from "antd";
import React, { useState } from "react";
import { fetcher } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TelegramIcon } from "../../assets/Icons";
import Loader from "../common/Loader";

const ServiceRequestForm = ({ userEmail }) => {
  const [name, setName] = useState("");
  const sitesetting = JSON.parse(localStorage.getItem('settings'));
  const [license, setLicense] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let options = [
    {
      value: "Joint Stock Company (JSC)",
      label: "Joint Stock Company (JSC)",
    },
    {
      value: "Sole Proprietorship 1 Person LLC",
      label: "Sole Proprietorship 1 Person LLC",
    },
    {
      value: "Branch of a Foreign Company",
      label: "Branch of a Foreign Company",
    },
    {
      value: "Foreign Company LLC",
      label: "Foreign Company LLC",
    },
    {
      value: "Technical and Scientific offices of Foreign Companies",
      label: "Technical and Scientific offices of Foreign Companies",
    },
    {
      value: "RHQ (Regional Head Quarters)",
      label: "RHQ (Regional Head Quarters)",
    },
  ];

  const createItem = async () => {
    setLoading(true);
    let payload = {
      email: userEmail,
      your_company_name: name,
      type_of_license: license,
      type_of_license_column_id: sitesetting.selectedColumn.type_of_license,
      email_column_id: sitesetting.selectedColumn.email,
      name_column_id: sitesetting.selectedColumn.company
    };
    try {
      const data = await fetcher(
        "/incorpify/createItem",
        "POST",
        JSON.stringify(payload)
      );
      if (data.success) {
        setTimeout(() => {
          navigate("/request-list");
        }, 11000);
      } else {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Something went wrong!");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  };

  return (
    <>
      <Flex vertical gap={20}>
        {loading && <Loader />}
        <Flex
          vertical
          gap={25}
          className="pt-5"
          style={{ paddingBottom: "35px" }}
        >
          <div>
            <Input
              style={{
                fontSize: "13px",
              }}
              size="large"
              variant="filled"
              value={name}
              placeholder="Your Company Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div>
            <Select
              size="large"
              placeholder="Type of license"
              variant="filled"
              style={{ flex: 1, width: "100%", borderRadius: "8px" }}
              onChange={(value) => setLicense(value)}
              options={options}
            />
          </div>
        </Flex>
        <div className="w-100 d-flex justify-content-center">
          <Button
            disabled={name === "" || license === ""}
            size="large"
            style={{
              width: "100%",
              border: "none",
              background: name === "" || license === "" ? "#ecedf5" : "#ff6221",
              color: name === "" || license === "" ? "#32333861" : "#ffffff",
            }}
            icon={<TelegramIcon />}
            onClick={() => createItem()}
          >
            <span
              style={{ fontSize: "15px", fontFamily: "montserrat, sans-serif" }}
            >
              Submit
            </span>
          </Button>
        </div>
      </Flex>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default ServiceRequestForm;