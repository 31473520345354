import React, { useState } from "react";
import {
  BagIcon,
  HelpIcon,
  LetsDoItIcon,
  Rocket,
  SmallHorizontalLine,
} from "../assets/Icons";
import { Modal } from "antd";
import ServiceRequestForm from "./Forms/ServiceRequestForm";

const Card = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const userEmail = localStorage.getItem("userEmail" || "");

  const siteSetting = JSON.parse(localStorage.getItem("settings")) || {
    meeting_link: "https://calendly.com/tascoutsourcing/30min",
  };

  return (
    <>
      <div className="card-container">
        <div
          className="d-flex flex-column gap-4"
          style={{
            background: "#F7F7F7",
            padding: "30px",
            margin: "0 10px",
            position: "relative",
          }}
        >
          <div>
            <span className="card-image">
              <BagIcon />
            </span>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="card-title-wrapper">
              <div className="card-title">
                <span>Incorporate your business now in Saudi Arabia</span>
              </div>
            </div>
            <p style={{ color: "#928F8F", fontSize: "17px" }}>
              Incorporate your business now in Saudi Arabia and take advantage
              of a thriving economy with unmatched opportunities for growth. Our
              expert team will handle all the complexities of the incorporation
              process, ensuring a smooth and hassle-free experience.{" "}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <a
                href="#"
                className="text-decoration-none"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <button
                  style={{
                    background: "#434343",
                    color: "#ffffff",
                    position: "relative",
                  }}
                  className="hit-loading btn btn-to-link btn-secondary ms-3 btn-gradiant  d-flex align-items-center justify-content-around"
                  type="button"
                >
                  <span
                    className="icon-btn_track"
                    style={{
                      height: "22px",
                      width: "30px",
                      paddingLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rocket />
                  </span>
                  <span>Lets do it!</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column gap-4"
          style={{
            background: "#F7F7F7",
            padding: "30px",
            margin: "0 10px",
            position: "relative",
          }}
        >
          <div>
            <span className="card-image">
              <HelpIcon />
            </span>
          </div>
          <div className="d-flex flex-column gap-4">
            <div className="card-title-wrapper">
              <div className="card-title">
                <span>Have a question? Request a free consultation!</span>
              </div>
            </div>
            <p style={{ color: "#928F8F", fontSize: "17px" }}>
              Have a question? Request a free consultation and get expert advice
              tailored to your business needs. Our experienced consultants are
              ready to assist you with personalized guidance on everything from
              incorporation to navigating regulatory requirements.{" "}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <a
                href="#"
                className="text-decoration-none"
                onClick={(e) => {
                  e.preventDefault();
                  setIsHelpModalOpen(true);
                }}
              >
                <button
                  style={{
                    background: "#434343",
                    color: "#ffffff",
                    position: "relative",
                  }}
                  className="hit-loading btn btn-to-link btn-secondary ms-3 btn-gradiant d-flex align-items-center justify-content-around"
                  type="button"
                >
                  <span
                    className="icon-btn_track"
                    style={{
                      height: "22px",
                      width: "30px",
                      paddingLeft: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LetsDoItIcon />
                  </span>
                  <span>I need help?</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        footer={null}
        className="service__form-modal"
        centered
      >
        <div className="p-5">
          <div className="d-flex flex-column">
            <div
              style={{}}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <div
                className="inc-start-form__heading"
                style={{
                  color: "#6F7490",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Let's get started
              </div>
              <div className="d-flex justify-content-center">
                <SmallHorizontalLine />
              </div>
              <div
                className="text-center px-1"
                style={{
                  color: "#928F8F",
                  fontSize: "19px",
                }}
              >
                Please fill out the form to proceed with the needed steps to
                incorporate your company in KSA
              </div>
            </div>
            <div
              className="inc-service-form"
              // style={{ boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px" }}
            >
              <ServiceRequestForm userEmail={userEmail} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        open={isHelpModalOpen}
        onCancel={() => setIsHelpModalOpen(!isHelpModalOpen)}
        footer={null}
        className="service__form-modal"
        centered
        closeIcon={
          <span
            style={{
              width: "30px",
              height: "30px",
              background: "#ffffff",
              borderRadius: "50%",
            }}
          >
            X
          </span>
        }
        style={{
          maxWidth: "1020px",
        }}
        width="100%"
      >
        <iframe
          src={
            siteSetting.meeting_link
              ? siteSetting.meeting_link
              : "https://calendly.com/tascoutsourcing/30min"
          }
          style={{
            border: 0,
            width: "100%",
            height: "91vh",
            paddingBottom: "25px",
            borderRadius: "8px",
          }}
          title="Calendly Scheduling"
        />
      </Modal>
    </>
  );
};

export default Card;
