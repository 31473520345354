import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import { getUserDetails, userSettingData } from "../../utils/tools";
import Loader from "../common/Loader";
import { isTokenValid, removeSessions } from "../../utils/helper";

const PanelWrapper = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let token = localStorage.getItem("userToken");

  const getDetails = async () => {
    setLoading(true);
    await getUserDetails();
    await userSettingData();
    setLoading(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("role") &&
      localStorage.getItem("role") === "customer"
    ) {
      if (token !== null && token !== undefined && token !== "") {
        let status = isTokenValid(token);
        if (status.valid) {
          // navigate("/");
        } else {
          removeSessions();
          navigate("/signin");
        }
      } else {
        removeSessions();
        navigate("/signin");
      }
    } else if (
      localStorage.getItem("role") &&
      (localStorage.getItem("role") === "admin" ||
        localStorage.getItem("role") === "superAdmin")
    ) {
      if (token !== null && token !== undefined && token !== "") {
        let status = isTokenValid(token);
        if (status.valid) {
          navigate("/admin-setting");
        } else {
          removeSessions();
          navigate("/signin");
        }
      } else {
        removeSessions();
        navigate("/signin");
      }
    } else {
      navigate("/signin");
    }
  }, []);

  if (loading) {
    <Loader />;
  }

  return (
    <>
      <Header showOnlyLogo={false} />
      <div className="container d-flex flex-column h-100 text-center">
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PanelWrapper;
